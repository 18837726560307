// export enum ReportDataType {
//     Text = 0,
//     LongText = 1,
//     Number = 2,
//     DatePicker = 3,
//     Media = 4,
//     Dropdown = 5,
//     MultipleDropdown = 6,
//     LinkedType = 7,
//     Bool = 8,
//     BoolAndLongText = 9,
//     BoolAndLongTextAndMedia = 10,
//     LongTextAndMedia = 11,
//     TextAndDatePicker = 12,
//     DropdownAndDatePicker = 13,
//     MultipleDropdownAndLongText = 14,
//     BoolAndDropdownAndLongText = 15,
//     DropdownAndLongText = 16,
// }

export default [
    {
        name: "",
        title: "1.0 THE BOARD OF DIRECTORS",
        description: "",
        questions: [
            {
                question: "Does the Board have a minimum of five (5) members?",
                isRequired: true,
                uniqueName: "R1Q1",
                entryName: "r1q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "2.0 FAMILY AND INTERLOCKING DIRECTORSHIP",
        description: "",
        questions: [
            {
                question: "Do more than two members of the same family sit on the Board?",
                isRequired: true,
                uniqueName: "R2Q1",
                entryName: "r2q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Are there any cross membership on the boards of two or more companies by directors of the company?",
                isRequired: true,
                uniqueName: "R2Q2",
                entryName: "r2q2",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "3.0 PROVISION OF MEETING DOCUMENTS TO OFFICERS OF THE BOARD",
        description: "",
        questions: [
            {
                question: "Are Board papers made available to members of the Board at least one week prior to the date of the Board or committee meeting?",
                isRequired: true,
                uniqueName: "R3Q1",
                entryName: "r3q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "4.0 INDEPENDENT NON-EXECUTIVE DIRECTOR",
        description: "",
        questions: [
            {
                question: "Does the public company have a minimum of one Independent Director on its Board?",
                isRequired: true,
                uniqueName: "R4Q1",
                entryName: "r4q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Are the Independent Directors: <br /> i. Partners or Executives of the company's statutory audit firm, internal audit firm, legal or other consulting firm that have a material association with the company? or <br /> ii. Partners or Executives of any such firm for three financial years preceding their appointment?",
                isRequired: true,
                uniqueName: "R4Q2",
                entryName: "r4q2",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "5.0 NOMINATION AND GOVERNANCE",
        description: "",
        questions: [
            {
                question:
                    "Does the Nomination and Governance Committee: <br /> i. Review the performance and effectiveness of the Board of the company’s subsidiary on an annual basis? <br /> ii. Prepare job specification for the position of the Chairman, including an assessment of time commitment required of the candidate? <br /> iii. Provide input to the annual report of the company in respect of Director compensation?",
                isRequired: true,
                uniqueName: "R5Q1",
                entryName: "r5q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "6.0 AUDIT COMMITTEE",
        description: "",
        questions: [
            {
                question: "Whenever necessary, does the Committee obtain external professional advice?",
                isRequired: true,
                uniqueName: "R6Q1",
                entryName: "r6q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "7.0 RISK MANAGEMENT COMMITTEE",
        description: "",
        questions: [
            {
                question: "Does the CEO/MD, Executive Directors and the Head of the internal audit unit attend the meetings of the Risk Management Committee?",
                isRequired: true,
                uniqueName: "R7Q1",
                entryName: "r7q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "8.0 APPOINTMENT TO THE BOARD",
        description: "",
        questions: [
            {
                question:
                    "In appointing a person to the Board, are shareholders provided with information on any real or potential conflict of interest, including whether the proposed appointee is an interlocking director?",
                isRequired: true,
                uniqueName: "R8Q1",
                entryName: "r8q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Do the letters of appointment cover: <br />i. Synopsis of Director's rights? <br />ii. Director evaluation programme used by the company? <br />iii. Any other contractual responsibilities?",
                isRequired: true,
                uniqueName: "R8Q2",
                entryName: "r8q2",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "9.0 BOARD EVALUATION",
        description: "",
        questions: [
            {
                question:
                    "i. Does the Chairman oversee the annual evaluation of the performance of the Chief Executive Officer? <br /> ii. Does the CEO/MD perform an annual evaluation for the Executive Directors based on agreed criteria or performance indicators?",
                isRequired: true,
                uniqueName: "R9Q1",
                entryName: "r9q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "10.0 REMUNERATION",
        description: "",
        questions: [
            {
                question: "Does the remuneration policy define a process for determining Executive and Non-Executive Directors' compensation?",
                isRequired: true,
                uniqueName: "R10Q1",
                entryName: "r10q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Does the Board approve the remuneration of each Executive Director including the CEO individually, taking into consideration direct relevance of skill and experience to the company at that time?",
                isRequired: true,
                uniqueName: "R10Q2",
                entryName: "r10q2",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "i. Where share options are adopted as part of executive remuneration or compensation, does the Board ensure that they are not priced at a discount except with the authorization of the SEC and that any such deferred compensation are not exercisable until one year after the expiration of the minimum tenor of directorship? <br />ii. Where share options are granted as part of remuneration to Directors, are the limits set in any given financial year and subject to the approval of the shareholders in general meeting?",
                isRequired: true,
                uniqueName: "R10Q3",
                entryName: "r10q3",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: `Does the company disclose in their annual report, details of shares held by all Directors, including on an "if-converted" basis?`,
                isRequired: true,
                uniqueName: "R10Q4",
                entryName: "r10q4",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Are Directors required to disclose their shareholding whether on a proprietary or fiduciary basis in the public company in which they are proposed to be appointed as Directors, prior to their appointment?",
                isRequired: true,
                uniqueName: "R10Q5",
                entryName: "r10q5",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Does the Board undertake a periodic peer review of its compensation and remuneration levels to ensure that the company remains competitive?",
                isRequired: true,
                uniqueName: "R10Q6",
                entryName: "r10q6",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "11.0 INTERNAL AUDIT FUNCTIONS",
        description: "",
        questions: [
            {
                question:
                    "Does the annual risk-based internal audit plan: <br />i. address the broad range of risks facing the company linking this to risk management framework? <br />ii. identify audit priority areas and areas of greatest threat to the company; <br />iii. indicate how assurance will be provided on the company's risk management process? <br />iv. indicate the resources and skills available or required to achieve the plan?",
                isRequired: true,
                uniqueName: "R11Q1",
                entryName: "r11q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "12.0 BUSINESS CONDUCTS AND ETHICS",
        description: "",
        questions: [
            {
                question:
                    "Are Directors, Management and other employees obligated to comply with the principles of the Code of Business Conduct and Ethics at all times, including to: <br />i. protect and ensure the efficient use of the Company's assets for legitimate business purposes? <br />ii. make reasonable enquiries to ensure that the Company is operating efficiently, effectively and legally, towards achieving its goals? <br />iii. not engage in conduct likely to bring discredit upon the company, and should encourage fair dealing by all employees with the Company's customers, suppliers, competitors and other employees? <br />iv. not take advantage of Company property or use such property for personal gain or to compete with the Company? <br />v. keep confidential, information received in the course of the exercise of their duties and such information remains the property of the Company from which it was obtained and it is improper to disclose it, or allow it to be disclosed, unless that disclosure has been authorized by the person from whom the information is provided, or is required by law?",
                isRequired: true,
                uniqueName: "R12Q1",
                entryName: "r12q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "13.0 SUSTAINABILITY",
        description: "",
        questions: [
            {
                question: "Does the company recognize corruption as a major threat to business and national development and therefore as a sustainability issue for businesses in Nigeria?",
                isRequired: true,
                uniqueName: "R13Q1",
                entryName: "r13q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Does the company, Board and individual directors commit themselves to transparent dealings and the establishment of a culture of integrity and zero tolerance to corruption and corrupt practices?",
                isRequired: true,
                uniqueName: "R13Q2",
                entryName: "r13q2",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "14.0 DISCLOSURE",
        description: "",
        questions: [
            {
                question: "Does the company engage in increased disclosure beyond the statutory requirements in the CAMA, in order to foster good corporate governance?",
                isRequired: true,
                uniqueName: "R14Q1",
                entryName: "r14q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "Other documentation",
        description: "Add any other document to support/augment your report. Clicking “upload document” would allow you provide more context to each document.",
        questions: [
			{
                question: "",
                isRequired: true,
                uniqueName: "",
                entryName: "documentation",
                orderIndex: 1,
                dataType: 4,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
		],
    },
];
